import { Component } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AjustesProvider } from '../services/ajustes';
import { aseguradorasService } from './aseguradoras.service';
import { NgbHighlight, NgbModal, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormGroup, FormsModule, ReactiveFormsModule, FormArray,FormControl } from '@angular/forms';
import { CommonModule, formatDate } from '@angular/common';
import { FeatherModule } from 'angular-feather';
import { FormatoService } from '../formatos/formatos.service';
import { NotifierService, NotifierModule } from 'angular-notifier';
;

@Component({
  selector: 'app-aseguradoras',
  templateUrl: './aseguradoras.component.html',
  styleUrls: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    FeatherModule,
    NgbHighlight,
    NotifierModule
  ],
  standalone: true,
  providers: [
    aseguradorasService,
    FormatoService,
    
  ]
})

export class AseguradorasComponent {

  credenciales:any = [];
  owner:string = 'Seleccione una opción';
  aseguradora:string = 'Seleccione una opción';
  nueva_empresa:string = '';
  usuarios:any = [];
  empresas:any = [];
  next:string = '';
  previous:string = '';
  aseguradoras:any = [];
  credencial_seleccionada:any = {};
  credencial_seleccionada_active = true;
  aseguradorasSeleccionadas: { [key: number]: boolean } = {};

  constructor(
    private _router: Router,
    private _ajustes: AjustesProvider,
    private aseguradorasService : aseguradorasService,
    private modalService: NgbModal,
    private credencialesService: FormatoService,
    private notifier_ :NotifierService
  ){
    window.scrollTo(0, 0);
    
    this.aseguradorasService.getAseguradoras().subscribe((response:any) =>{
      this.aseguradoras = response['results'];
      
      this.inicializarCheckboxes();
      
    });

    
    this.aseguradorasService.getEmpresas().subscribe((response:any) =>{
      this.empresas = response;
      this.empresas.unshift({nombre:'Seleccione una opción'})});
    
  }
  inicializarCheckboxes() {
    this.aseguradoras.forEach(aseguradora => {
      this.aseguradorasSeleccionadas[aseguradora.id] = false; 
    });
  }

  
  changeEmpresa() {
    if (this.owner) {
        const empresaSeleccionada = this.empresas.find(empresa => empresa.nombre === this.owner);
        if (empresaSeleccionada && empresaSeleccionada.aseguradoras) {
            
            
            this.inicializarCheckboxes();
            
            
            empresaSeleccionada.aseguradoras.forEach(aseguradora => {
                const aseguradoraId = this.aseguradoras.find(a => a.numero === aseguradora);
                if (aseguradoraId) {
                    this.aseguradorasSeleccionadas[aseguradoraId.id] = true;
                    console.log(this.aseguradorasSeleccionadas);
                }
            });
        }
    }
}

guardarSeleccion() {
  if (this.owner) {
      const empresaSeleccionada = this.empresas.find(empresa => empresa.nombre === this.owner);
      if (empresaSeleccionada) {
          const aseguradorasAEnviar = [];

          for (const [id, seleccionada] of Object.entries(this.aseguradorasSeleccionadas)) {
              if (seleccionada) {
                  aseguradorasAEnviar.push(Number(id));  
              }
          }
          const payload = {
              aseguradoras: aseguradorasAEnviar  
          };

          this.aseguradorasService.actualizarEmpresa(empresaSeleccionada.id, payload).subscribe(response => {
              console.log('Aseguradoras actualizadas:', response);
          }, error => {
              console.error('Error al actualizar aseguradoras:', error);
          });
      }
  }
}


  
}
